import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Courses from "./pages/Courses/Courses";
import Contact from "./pages/Contact/Contact";
import Profile from "./pages/Profile/Profile";
import Login from "./pages/Login/Login";
import Singup from "./pages/Singup/Singup";
import Error from "./pages/Error/Error";
import "animate.css";
import { useEffect, useState } from "react";
import Exam from "./pages/Exam/Exam";
//Vendors
import "./vendors/bootstrap/css/bootstrap.min.css";
import "./vendors/animate/animate.min.css";
import "./vendors/animate/custom-animate.css";
import "./vendors/fontawesome/css/all.min.css";
import "./vendors/odometer/odometer.min.css";
import "./vendors/swiper/swiper.min.css";
import "./vendors/icomoon-icons/style.css";
import "./vendors/tiny-slider/tiny-slider.min.css";
import "./vendors/reey-font/stylesheet.css";
import "./style/zilom.css";
import "./style/zilom_ar.css";
import "./style/zilom-responsive.css";
import academyLogo from"./images/academy-logo.png"
import Wrappers from "./component/ui/Wrappers";
import Footer from "./component/ui/Footer";
import ScrollUp from "./component/ui/ScrollUp";
// import LessonQuizePage from "./pages/Lessons/component/lesson-quize/LessonQuizePage";
import NavBar4 from "./component/NavBar";
import LogOut from "./store/LogoutComponent";
import CustomCourseDetails from "./pages/Courses/component/ui/CustomCourseDetails";
import MyCoursesPage from "./pages/Courses/my-courses/MyCoursesPage";
// import CourseQuizePage from "./pages/Lessons/component/course-quize-page/CourseQuizePage";
// import AttemptPage from "./pages/Lessons/component/attempt-page/AttemptPage"
import Loading from "./component/loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { getUiSettingsFunc } from "./store/slices/ui-settings-actions/GetUiSettingsSlicer";
import ContextApi from "./store/slices/Context";
import firstSliderImg from "./images/slide1.jpg"
import secondSliderImg from "./images/slide2.jpg"
import thirdSliderImg from "./images/slide3.jpg"
import RegisterPage from "./pages/register-page/RegisterPage";
import InvoicesPage from "./pages/invoices-page/InvoicesPage";
import ResetPassPage from "./pages/reset-pass-page/ResetPassPage";
import VerifyEmailPage from "./pages/verify-email-page/VerifyEmailPage";
import OverLayLoading from "./component/global/overlay-loading/OverLayLoading";
import { getVerifyEmailFunc } from "./store/slices/users/VerifyEmailSlicer";
import PaymentStatusPage from "./pages/payment-status-page/PaymentStatusPage";
import SingleLessonPage from "./pages/single-lesson-page/SingleLessonPage";
// import FreeAttemptsPage from "./pages/Lessons/component/free-attemp-page/FreeAttemptsPage";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import ShowCoursePage from "./pages/show-course/ShowCoursePage";
import CheckoutPage from "./pages/checkout/CheckoutPage";
import { getProfileData } from "./store/slices/users/profileSlicer";
import SuccessActivationCourse from "./pages/success-activation-course/SuccessActivationCourse";
import { getLanguageSettingsFunc } from "./store/slices/language/GetLanguageSettingsSlicer";
import i18n from "i18next";
import TeacherProfile from "./pages/Home/component/teachers/teacher-profile/TeacherProfile";
import TeachersPage from "./pages/teachers/TeachersPage";
function App() {
  const userData = JSON.parse(window.localStorage.getItem("userData"));
  const userToken = window.localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const nameLocation = location.pathname;
  const userId = userData && userData.data && userData.data.student && userData.data.student.id;
  const {loading:getVerifingEmailLoading} = useSelector(
    state => state.getVerifyEmailSlicer
  )
  const getVerifingEmail = async (apiLink , data) => {
    try {
      dispatch(getVerifyEmailFunc({api : apiLink , data}))
      .then(result => {
        console.log(result);
        if(result.payload.success === true) {
          navigate("/login");
          toast.success(result?.payload?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } 
      })
    }
    catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const routes = [
    {
      path: "/",
      component: Home,
    },
    {
      path: "/about",
      component: About,
    },
    {
      path: "/reset-pass",
      component: ResetPassPage ,
    },
    {
      path: "reset-password",
      component: ResetPassPage ,
    },
    {
      path: "/login/reset-pass",
      component: ResetPassPage ,
    },
    {
      path: "/contact",
      component: Contact,
    },
    {
      path: "/courses",
      component: Courses,
    },
    {
      path: "/cart/courses",
      component: Courses,
    },
    {
      path: "/courses/:excutionId",
      component: CustomCourseDetails,
    },
    {
      path: "/courses/:excutionId/single-lesson/:lessonId",
      component: SingleLessonPage,
    },
    {
      path: "/my-courses",
      component: MyCoursesPage,
    },
    {
      path: "/profile",
      component: Profile ,
    },
    {
      path: "/cart/profile",
      component: Profile ,
    },
    {
      path: "/Login",
      component: Login,
    },
    {
      path: "/register",
      component: RegisterPage,
    },
    {
      path: "/Singup",
      component: Singup,
    },
    {
      path: "/profile/invoices/:studentId",
      component: InvoicesPage ,
    },
    {
      path: "/cart/profile/invoices/:studentId",
      component: InvoicesPage ,
    },
    {
      path: "/exam/:executionId",
      component: Exam,
    },
    {
      path: "/order/callback",
      component: PaymentStatusPage ,
    },
    {
      path: "/error",
      component : Error,
    },
    {
      path: "/verify-email",
      component: VerifyEmailPage,
    },
    {
      path: "/lessons/:executionId",
      component: ShowCoursePage ,
    },
    {
      path: "/checkout/:executionId",
      component: CheckoutPage ,
    },
    {
      path: "/instructors",
      component: TeachersPage ,
    },
    {
      path: "/instructor-profile/:teacherId",
      component: TeacherProfile ,
    },
    {
      path: "/success-activation/:executionId",
      component: SuccessActivationCourse ,
    },
    {
      path: "*",
      component : Error,
    },
  ];


  const defaultSettings =  {
    generalSetting: {
      id: 1,
      platform_name: "الأوائل",
      emails: [
        "info@company.com",
        "test@company.com"
      ],
      phones: [
        "92 666 888 0000",
        "92 666 888 3232"
      ],
      addresses: [
        "88 broklyn street, USA",
        "90 broklyn street, USA"
      ],
      about: "مرحبًا بك في منصتنا التعليمية، حيث نقدم لك فرصة فريدة لاكتساب المعرفة وتحقيق النجاح الأكاديمي. من خلال مجموعة متنوعة من الدروس والموارد التعليمية عبر الإنترنت، نسعى جاهدين لتوفير بيئة تعليمية محفزة وملهمة تمكنك من تحقيق أهدافك التعليمية بسهولة ويسر. انضم إلينا اليوم وابدأ رحلتك في عالم المعرفة والتعلم الشيقة.",
      more_about: "  مرحبًا بك في منصتنا التعليمية المبتكرة، حيث يجتمع التعليم التقليدي بالتكنولوجيا الحديثة لتوفير تجربة تعليمية مثيرة ومميزة. نحن نؤمن بأن التعلم يجب أن يكون ممتعًا وملهمًا، وهذا هو السبب في تصميم منصتنا لتلبية احتياجات كل متعلم وتحفيزه لتحقيق أقصى إمكاناته.ز منصتنا بمحتوى تعليمي ذو جودة عالية، وتفاعلية مبتكرة، ودعم فني متميز لضمان تجربة تعلم فريدة وفعالة. سواء كنت طالبًا جديدًا أو محترفًا في مجالك، فإننا نعمل جاهدين لتوفير بيئة تعليمية تحفزك على استكشاف المعرفة وتطوير مهاراتك بكل ثقة.",
      students_session_duration: 525600,
      users_session_duration: 525600,
      created_at: "2024-03-01T12:52:05.000000Z",
      updated_at: "2024-03-01T12:52:05.000000Z",
      platform_logo_url: academyLogo
    },
    sliderImages: [
      {
      id: 1,
      title: "استمتع برحلة التعلم",
      description: "اكتشف متعة التعلم الذاتي والتطوير",
      link: "https://alawa2el.com/courses",
      created_at: "2024-03-01T12:52:05.000000Z",
      updated_at: "2024-03-01T12:52:05.000000Z",
      image_url: firstSliderImg,
      mobile_image_url: "https://test-apis.alawa2el.com/storage/questions/exam.jpg"
      },
      {
      id: 2,
      title: "اختر التعلم",
      description: "اجعل التعلم جزءًا من حياتك<br>استمتع بمغامرة تعلم ممتعة.",
      link: "https://alawa2el.com/courses",
      created_at: "2024-03-01T12:52:05.000000Z",
      updated_at: "2024-03-01T12:52:05.000000Z",
      image_url: secondSliderImg,
      mobile_image_url: "https://test-apis.alawa2el.com/storage/questions/exam.jpg"
      },
      {
        id: 3,
        title: "منصة التعلم الرائدة",
        description: "تجربة فريدة تنقلك بعيدًا عن<br> العادي إلى التميز",
        link: "https://alawa2el.com/courses",
        created_at: "2024-03-01T12:52:05.000000Z",
        updated_at: "2024-03-01T12:52:05.000000Z",
        image_url: thirdSliderImg,
        mobile_image_url: "https://test-apis.alawa2el.com/storage/questions/exam.jpg"
      }
    ]
  }
  const [loadingDone , setLoadingDone] = useState();
  const [uiSettings , setUiSettings] = useState(defaultSettings);
  const global_data = {
    mainApi : process.env.REACT_APP_PUBLIC_API,
    platformData  : uiSettings 
  }
  
  const {loading:getUiSettingsLoading , data} = useSelector(
    state => state.getUiSettingsSlicer
  )
  const dispatch = useDispatch();
  const uiSettingsApi = `${global_data.mainApi}/api/settings`;
  const {loading:getLanguagesLoading , data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
  useEffect(() => {
    dispatch(getUiSettingsFunc(uiSettingsApi))
  },[])
  useEffect(() => {
    data && setUiSettings(data);
  },[data])
  useEffect(() => {
    !getUiSettingsLoading && setTimeout(() => setLoadingDone(true) , 2000)
  },[])

  useEffect(() => {
    const maniURL = window.location.href;
    const locationUrl = new URL(maniURL);
    const RegHash = /verify-email\/\d+\/(\w+)/;
    const match = maniURL.match(RegHash);
    let hash;
    if (match) {
      hash = match[1];
    }
    const hashVerifyUrl = locationUrl.searchParams.get("email_verify_url");
    if(hashVerifyUrl) {
      const hashVerifyUrlSearch = new URL(hashVerifyUrl);
      const expires = hashVerifyUrlSearch.searchParams.get("expires")
      const signature = locationUrl.searchParams.get("signature");
      const data = {
        expires ,
        signature,
      }
      const getVerifingEmailApi = `${global_data.mainApi}/api/verify-email/${userId}/${hash}`;
      getVerifingEmail(getVerifingEmailApi , data)
    }
  },[])

  useEffect(() => {
    let resetPassToken;
    const maniURL = window.location.href;
    const regResetPassToken = /token=([^&]+)/;
    // const regResetPassToken = /token=.*/;
    const match = maniURL.match(regResetPassToken);
    if (match) {
      resetPassToken = match[1];
    }
    if(maniURL.includes("token")) {
      console.log("has reset token");
    }else {
      console.log("no token");
    }
  },[])


  const changeTitle = (newTitle) => {
    document.title = newTitle;
  };
  const changeFavicon = (iconURL) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = iconURL;
  };
  const changeMetaDescription = (newDescription) => {
    // Find the meta tag with the name "description"
    const metaDescription = document.querySelector('meta[name="description"]');
  
    // If the meta tag is found, update its content attribute
    if (metaDescription) {
      metaDescription.setAttribute('content', newDescription);
    } else {
      // If the meta tag doesn't exist, create one and append it to the head
      const meta = document.createElement('meta');
      meta.setAttribute('name', 'description');
      meta.setAttribute('content', newDescription);
      document.head.appendChild(meta);
    }
  };
  useEffect(() => {
    // Change the favicon to a new icon URL
    changeFavicon(uiSettings.site_logo);
    changeTitle(uiSettings.site_title)
    changeMetaDescription(uiSettings.site_description)
    // You can also revert back to the original favicon on unmount if needed
    return () => {
      changeFavicon(uiSettings.site_logo);
      changeTitle(uiSettings.site_title);
      changeMetaDescription(uiSettings.site_description)
    };
  }, [uiSettings]); 
  useEffect(() => {
    const userToken = window.localStorage.getItem("token");
    if(userToken) {
      axios.defaults.headers.common = {'Authorization' : `Bearer ${userToken}`};
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
      // getSettingsFunc();
    }
  },[])
  // const removeNav = location.pathname.startsWith('/lessons/') || location.pathname.startsWith('/checkout/');
  const removeNav = location.pathname.startsWith('/checkout/') || location.pathname.startsWith('/success-activation/');  
  const profileApi = `${process.env.REACT_APP_PUBLIC_API}/api/profile`;
  useEffect(() => {
    const userToken = window.localStorage.getItem("token");
    const getProfile = async () => {
      try {
        userToken && dispatch(getProfileData(profileApi))
        // dispatch(getStudentCoursesFunc())
      } catch (error) {
        console.log(error);
      }
    };
    getProfile();
  }, []);

  const languageAPi = `${global_data.mainApi}/api/language-settings`;
  useEffect(() => {
      const getSettings = () => {
          // dispatch(getGlobalSettingsFunc(settingsApi))
          if(window.localStorage.getItem("langSlug")) {
            dispatch(getLanguageSettingsFunc(`${languageAPi}?lang=${window.localStorage.getItem("langSlug")}`))
          }else {
            dispatch(getLanguageSettingsFunc(languageAPi))
          }
      }
      getSettings()
  },[])
  useEffect(() => {
    const fetchTranslations = async () => {
      if (languageData) {
        const { defaultLang, all_word } = languageData;
        if (defaultLang && all_word) {
          const { direction, slug } = defaultLang;
          document.documentElement.dir = direction; // Set the direction dynamically
          window.localStorage.setItem("langSlug" , defaultLang.slug);
          console.log(defaultLang.slug)

          i18n.addResources(slug, 'translation', all_word);
          i18n.changeLanguage(slug);
          if (defaultLang.slug && defaultLang.slug === "en" && window.localStorage.getItem("langSlug") === 'en') {
            document.documentElement.setAttribute('dir', 'ltr');
            document.documentElement.setAttribute('lang', 'en');
          }
        } else {
          console.error('Invalid language data structure', languageData);
        }
      }
    };
    fetchTranslations();
  }, [languageData]);
  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000,
  //     once: true,
  //   })
  // })
  return (
    <>
      
      {      
      (loadingDone && data && global_data.platformData && !getLanguagesLoading)
      ?
      <>
      <ContextApi.Provider value={uiSettings}>
        <Wrappers>
          {
            nameLocation !== "/login" 
            &&
            nameLocation !== "/register" 
            &&
            nameLocation !== "/reset-pass"
            &&
            nameLocation !== "/reset-password"
            &&
            nameLocation !== "/verify-email"
            &&
            nameLocation !== "/callback"
            &&
            nameLocation !== "/lessons/lesson-quize-page/:lessonId"
            &&
            nameLocation !== "/courses/:excutionId/lesson-quize-page/:lessonId"
            &&
            nameLocation !== "/lesson-quize-page/:lessonId"
            &&
            !removeNav
            &&
            <NavBar4/>
          }
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} exact path={`/${route.path}`} Component={route.component} />
              ))}
          </Routes>
          {
            userData
            &&
            <LogOut/>
          }
          {
            nameLocation !== "/login" 
            &&
            nameLocation !== "/register" 
            &&
            nameLocation !== "/reset-pass"
            &&
            nameLocation !== "/reset-password"
            &&
            nameLocation !== "/verify-email"
            &&
            // nameLocation !== "/contact"
            // &&
            nameLocation !== "/callback"
            &&
            !removeNav
            &&
          <Footer />
          }
        </Wrappers>
      </ContextApi.Provider>
        <ScrollUp />
      </>
      :
      <Loading/>
      }
      <OverLayLoading loading={getVerifingEmailLoading} loadingtext={"جاري تاكيد البريد الالكتروني.."} />
      <ToastContainer className={"z-[9999999999999]"}/>
      {/* <ToastContainer/> */}
    </>
  );
}

export default App;
