import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { getLessonData } from "../../store/slices/lessons/showLessonSlicer";
import { useContext, useEffect, useState } from "react";
import dfCourseImg from "../../images/df-course-img.webp"
import { useForm } from "react-hook-form";
import CustomLoaders from "../../component/ui/CustomLoaders";
import ContextApi from "../../store/slices/Context";
import visa1 from "../../images/visa (2).png";
import visa2 from "../../images/business.png";
import { Button} from "@mui/material";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { applayCouponeFunc } from "../../store/slices/copons-actions/ApplayCouponSlicer";
import { toast } from "react-toastify";
import { checkoutFunc } from "../../store/slices/checkout-actions/CheckoutSlicer";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // price
import PriceCheckIcon from '@mui/icons-material/PriceCheck'; // final price
import DiscountIcon from '@mui/icons-material/Discount'; //for course disc
import QrCodeIcon from '@mui/icons-material/QrCode'; //for promo code
import CastForEducationIcon from '@mui/icons-material/CastForEducation'; //for lesons
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl'; //for chapters
import paymentsMethodsImg from "../../images/payments-methods.png"
import { useTranslation } from "react-i18next";
export default function CheckoutPage() {
    const {t} = useTranslation();
    const { executionId } = useParams();
    const {register , handleSubmit , watch , setValue} = useForm();
    const token = window.localStorage.getItem("token")
    const exApi = `${process.env.REACT_APP_PUBLIC_API}/api/course-executions/${executionId}`;
    const applyCouponApi = `${process.env.REACT_APP_PUBLIC_API}/api/aplly-coupon`;
    const checkoutApi = `${process.env.REACT_APP_PUBLIC_API}/api/subscriptions`;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading: getExLoading, data: exData } = useSelector(state => state.shwoLessonSlicer);
    const { loading: applyCoupounLoading , data:couponDataFromApi} = useSelector(state => state.applayCouponeSlicer);
    const { loading: checkoutLoading , data:checkoutData} = useSelector(state => state.checkoutSlicer);
    const {
      loading: profileLoading,
      data: profileData,
      error: profileError,
    } = useSelector((s) => s.profileSlicer);
    const {data:languageData} = useSelector(
      state => state.getLanguageSettingsSlicer
    )
    const [executionData , setExecutionData] = useState({})
    const [courseData , setCourseData] = useState({})
    const [paymentType , setPaymnetType] = useState(null)
    const couponValue = watch("coupon_code")
    const contextApi = useContext(ContextApi)
    const site_global_currency = contextApi?.site_global_currency;
    const [receiptImage , setReciptImage] = useState();
    const [walletNumber , setWalletNumber] = useState(null);
    const [couponMessage , setCouponMessage] = useState("");
    const [couponData , setCouponData] = useState(null);
    const getEx = () => {
      dispatch(getLessonData(exApi));
    };
    useEffect(() => {
      getEx();
    }, []);
    useEffect(() => {
      if(exData) {
        console.log(exData)
        setExecutionData(exData?.courseExecution)
        setCourseData(exData?.courseExecution?.course_details)
      }
    },[exData])
    const paymentsMethods = [
      {id : 1 , name : "send_receipt"},
      {id : 2 , name : "paymob_card_gateway"},
      {id : 3 , name : "paymob_wallet_gateway"},
    ]
    const handleSetPaymentType = (payment) => {
      setPaymnetType(payment)
    }
    const switchPaymentsIcons = (payment) => {
      if(payment) {
        switch(payment) {
          case "send_receipt":
            return <ReceiptLongIcon/>
          case "paymob_card_gateway":
            return <CreditScoreIcon/>
          case "paymob_wallet_gateway":
            return <AccountBalanceWalletIcon/>
          default:
            return <AccountBalanceWalletIcon/>
        }
      }
    }
    useEffect(() => {
      if(window.localStorage.getItem("couponCode")) {
        const code = JSON.parse(window.localStorage.getItem("couponCode"))?.code
        setValue("coupon_code" , code);
        setCouponData(JSON.parse(window.localStorage.getItem("couponCode")))
      }
    },[setValue])
    const handleApplayCoupon = () => {
      if(couponValue !== "") {
        dispatch(applayCouponeFunc({api : applyCouponApi , data : {coupon_code : couponValue , course_execution_id : executionId}}))
        .then(result => {
          if(result.payload && result.payload.code && result.payload?.message !== "Request failed with status code 422") {
            if(result.payload.type === "fixed") {
              // setCouponMessage(`You Have Get ${result.payload.discount} ${site_global_currency} On Your Order!`);
              setCouponMessage(`${t("you have get")} ${result.payload.discount} ${site_global_currency} ${t("on your course")}`);
            }else {
              // setCouponMessage(`You Have Get ${result.payload.discount} % On Your Order!`);
              setCouponMessage(`${t("you have get")} ${result.payload.discount} % ${t("on your course")}`);
            }
            if(profileData) {
              window.localStorage.setItem("couponCode" , JSON.stringify({...result.payload , userEmail : profileData.email}))
              toast.success(result.payload.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }else {
            setCouponMessage("");
            if(window.localStorage.getItem("couponCode")) {
              window.localStorage.removeItem("couponCode");
            }
          }
        })
      }else {
        toast.error(t("قم بادخال كوبون الخصم"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    const handleClearCoupon = () => {
      setCouponMessage("");
      if (window.localStorage.getItem("couponCode")) {
        setCouponData(null);
        setValue("coupon_code" , null);
        window.localStorage.removeItem("couponCode");
      }
    };
    useEffect(() => {
      couponDataFromApi?.message !== "Request failed with status code 422" && setCouponData(couponDataFromApi)
      couponDataFromApi && console.log(couponDataFromApi)
    },[couponDataFromApi])
    const [discountAm , setDiscountAm] = useState(0);
    const [priceAfterDiscount , setPriceAfterDiscount] = useState();
    useEffect(() => {
      if(couponData && Object.keys(couponData).length >=1) {
        const discountAmount = couponData.type === "fixed" ? parseInt(couponData.discount) : executionData?.price * (parseInt(couponData.discount) / 100);
        setDiscountAm(discountAmount);
        if(parseInt(executionData?.discount) >= 1) {
          const finalPrice = executionData?.price - (parseInt(executionData?.discount) + parseInt(discountAmount))
          setPriceAfterDiscount(finalPrice)
        }else {
          const finalPrice = executionData?.price -  parseInt(discountAmount);
          setPriceAfterDiscount(finalPrice);
        }
      }else {
        if(parseInt(executionData?.discount) >= 1) {
          const finalPrice = executionData?.price - parseInt(executionData?.discount)
          setPriceAfterDiscount(finalPrice)
        }else {
          const finalPrice = executionData?.price
          setPriceAfterDiscount(finalPrice)
        }
      }
    },[couponData , executionData])
    const handleSubmitCheckout = () => {
      if(paymentType) {
        if(paymentType === "send_receipt" && !receiptImage) {
          toast.error(t("قم باختيار صوره الايصال"), {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
        }else {
          if(paymentType === "paymob_wallet_gateway" && !walletNumber) {
            toast.error(t("رقم محفظةالدفع مطلوب"), {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }else {
            const orderForm = new FormData();
            orderForm.append("course_execution_id" , executionId);
            orderForm.append("type_payment" , paymentType);
            couponData && orderForm.append("coupon_code" , couponData.code);
            receiptImage && orderForm.append("receipt_image" , receiptImage);
            walletNumber && orderForm.append("wallet_number" , walletNumber);
            dispatch(checkoutFunc({api : checkoutApi , data: orderForm}))
            .then((result) => {
              if(result.payload?.success === true) {
                  if(paymentType === "paymob_card_gateway" || paymentType === "paymob_wallet_gateway") {
                      const iframeApi = result.payload.data.response;
                      // window.open(iframeApi , "_blank");
                      window.open(iframeApi , "_self");
                      // navigate('/');
                  }else {
                      toast.success(result.payload.message, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                      });
                      // navigate("/");
                      window.localStorage.removeItem("couponCode")
                  }
              }
            })
          }
        }
      }else {
        toast.error(t("قم باختيار طريقة الدفع اولا"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    return (
        <>
            {
              getExLoading
              ?
              <div className="text-center py-1 h-[100vh] flex justify-center items-center">
                <CustomLoaders />
                <CustomLoaders />
                <CustomLoaders />
                <CustomLoaders />
              </div>
              :
              exData
              ?
              (
              <div className="page-content relative py-4">
                <div className="container">
                  <div className="row">
                    <div className={`custom-scroll relative col-sm-12 col-md-4 py-2 overflow-y-auto md:h-screen md:sticky md:shadow-lg md:shadow-slate-300 md:top-0 md:right-0`}>
                      <div className="relative rounded-md w-full h-[200px] overflow-hidden">
                        <img src={courseData?.image_url ? courseData.image_url : dfCourseImg} alt="course img" className="absolute top-0 left-0 w-full h-full" />
                      </div>
                      <form 
                        className="row mt-3"
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleApplayCoupon()
                        }}
                      >
                        <div className="col-sm-12">
                          <input 
                          {...register("coupon_code" , {required : t("كوبون الخصم مطلوب")})}
                          type="text" className="form-control col-sm-12 shadow-none" id="inputPassword2" placeholder={t("كوبون الخصم")}
                          disabled = {applyCoupounLoading}
                          />
                          {couponMessage ? <p className="text-green-500">{couponMessage}</p> : ""}
                        </div>
                        <div className="col-auto mt-2">
                          <Button type="submit" variant="contained" className={`btn btn-primary mb-3 ${(couponValue !==  "") ? " opacity-100" : "opacity-70 pointer-events-none"} transition-all duration-500`} style={{backgroundColor : "#139897"}}>
                            {
                              applyCoupounLoading
                              ?
                              t("جاري التحقق")
                              :
                              <>
                                {t("تحقق")}
                                <QrCodeIcon className="text-sm"/>
                              </>
                            }
                          </Button>
                          {
                            window.localStorage.getItem("couponCode")
                            ?
                            <Button variant="contained" className={`btn btn-primary mb-3 ${(!applyCoupounLoading) ? "" : "pointer-events-none"} transition-all duration-500 mx-2 bg-red-500 border-none outline-none shadow-none`}
                            onClick={handleClearCoupon}
                            >
                              {t("الغاء")}
                            </Button>
                            :
                            ""
                          }
                        </div>
                      </form>
                      <ul className="my-3">
                        <li className="mb-2 text-[#33373b]"
                        >
                          <MonetizationOnIcon className="text-primary"/>
                          {" "}
                          {t("سعر الاشتراك")}: {executionData?.price} {site_global_currency}
                        </li>
                        {/* {
                          <li className="mb-2 text-[#33373b]"
                          >
                            <ChecklistRtlIcon/>
                            {" "}
                            {t("عدد الفصول")} : ( {exData?.chapters_count} )
                          </li>
                        }
                        {
                          <li className="mb-2 text-[#33373b]"
                          >
                            <CastForEducationIcon/>
                            {" "}
                            {t("الدروس")} : ( {exData?.lessons_count} )
                          </li>
                        } */}
                        {
                          executionData?.discount >= 1
                          ?
                          <li className="mb-2 text-[#33373b]"
                          >
                            <DiscountIcon className="text-primary"/>
                            {" "}
                            {t("خصم")} : {executionData?.discount} {site_global_currency}
                          </li>
                          :
                          ""
                        }
                        {
                          couponData !== null
                          ?
                          <li className="mb-2 text-[#33373b]">
                            <QrCodeIcon className="text-primary"/>
                            {" "}
                            {t("كوبون")} {couponData && couponData.type && couponData.type === "fixed" ? "" : (couponData && `(${couponData && `${couponData.discount}%`})`)}
                            :
                            {" "}
                            {parseInt(discountAm)?.toFixed(2)}
                            {" "}
                            {site_global_currency}
                          </li>
                          :
                          ""
                        }
                        {
                          // priceAfterDiscount
                          // ?
                          <li className="mb-2 text-[#33373b]">
                            <PriceCheckIcon className="text-primary"/>
                            {" "}
                            {t("السعر النهائي")}
                            :
                            {" "}
                            {parseInt(priceAfterDiscount)?.toFixed(2)}
                            {" "}
                            {site_global_currency}
                          </li>
                          // :
                          // ""
                        }
                      </ul>
                      <Button variant="contained"
                      style={{backgroundColor : "#139897"}}
                      className={`col-sm-12 py-2 mt-2 ${(applyCoupounLoading || checkoutLoading) ? "pointer-events-none opacity-50" : "opacity-100"}`}
                      onClick={handleSubmitCheckout}
                      >
                        {
                          checkoutLoading
                          ?
                          t("يرجي الانتظار")
                          :
                          t("دفع الان")
                        }
                        <CreditScoreIcon className="mx-2"/>
                      </Button>
                    </div>
                    <div className="info-content relative col-sm-12 col-md-7">
                    <h1 className="font-bold text-xl mt-3 relative px-4 py-2">{courseData?.name} <span className={`absolute top-0 ${languageData?.defaultLang?.slug !== "ar" ? "left-0" : "right-0"} w-2 h-full bg-primary rounded-[10px]`}></span></h1>
                      {/* <h1 className="md:text-xl font-bold">{courseData?.name}</h1> */}
                      <div className="relative flex items-center">
                        <img src={courseData?.user?.image_url ? courseData?.user?.image_url : ""} alt="instructor-img" className="w-[50px] rounded-[50%]"/>
                        <div className="relative flex flex-col mx-2">
                        <h1 className="">{courseData?.user?.name}</h1>
                        <p className="text-sm">{executionData?.title}</p>
                        </div>
                      </div>
                      <div className="mt-3" dangerouslySetInnerHTML={{__html: courseData?.description}} />
                        <h1 className="font-bold text-xl mt-3 relative px-4 py-2">{t("طرق الدفع ")}( {paymentsMethods?.length} ) <span className={`absolute top-0 ${languageData?.defaultLang?.slug !== "ar" ? "left-0" : "right-0"} w-2 h-full bg-primary rounded-[10px]`}></span></h1>
                        <div className="payments-methods mt-2">
                          {
                            paymentsMethods?.length >= 1 &&
                            paymentsMethods.map((payment, index) => {
                              if(payment.name === "paymob_card_gateway") {
                                return (
                                  <div key={index} className={`payment-option payment-method-parent border-1 rounded-2 position-relative overflow-hidden row mx-0 my-3 transition-all duration-300 hover:border-blue-500 ${paymentType === payment?.name && "border-blue-500"}`}>
                                    <div className="position-relative d-flex align-items-center col-sm-4">
                                        <input 
                                            className="mx-1 my-0"
                                            style={{cursor : "pointer"}}
                                            id={index}
                                            value={payment.name}
                                            type="radio" 
                                            name="payment-gateway" 
                                            onChange={e => setPaymnetType(e.target.value)} />
                                            {t("فيزا")}
                                    </div>
                                    <label htmlFor={index} className={`col-sm-8 top-0 left-0 px-3 py-4 d-flex justify-end`} style={{cursor : "pointer"}}>
                                        <img src={visa1 } alt="Paymob" className="payment-logo" style={{width : "30px"}} />
                                        <img src={visa2 } alt="Paymob" className="payment-logo" style={{width : "30px"}} />
                                    </label>
                                  </div>
                                )
                              }
                              if(payment.name === "paymob_wallet_gateway") {
                                return (
                                  <>
                                  <div key={index} className={`payment-option payment-method-parent border-1 rounded-2 position-relative overflow-hidden row mx-0 my-3 transition-all duration-300 hover:border-blue-500 ${paymentType === payment?.name && "border-blue-500"}`}>
                                  <div className="position-relative d-flex align-items-center col-sm-4">
                                      <input 
                                          className="mx-1 my-0"
                                          style={{cursor : "pointer"}}
                                          id={index}
                                          value={payment.name}
                                          type="radio" 
                                          name="payment-gateway" 
                                          onChange={e => setPaymnetType(e.target.value)} />
                                          {t("محافظ الكترونية")}
                                    </div>
                                    <label htmlFor={index} className="col-sm-8 top-0 left-0 px-3 py-4 d-flex justify-content-end" style={{cursor : "pointer"}}>
                                        <img src="https://startuppakistan.com.pk/wp-content/uploads/2023/06/1-20.jpg" alt="Paymob" className="payment-logo" style={{width : "30px"}} />
                                    </label>
                                  </div>
                                  {
                                      paymentType === "paymob_wallet_gateway"
                                      ?
                                      <div className="row">
                                          <div className="mb-4 col-sm-12">
                                              <input 
                                              name="wallet-num"  
                                              className="form-control mx-0" 
                                              placeholder={t("رقم المحفظة")} 
                                              type="text"
                                              onChange={e=>setWalletNumber(e.target.value)}
                                              />
                                          </div>
                                      </div>
                                      :
                                      ""
                                  }
                                  </>
                              );
                              }
                              if(payment.name === "send_receipt"){
                                return (
                                  <>
                                    <div key={index} className={`payment-option payment-method-parent border-1 rounded-2 position-relative overflow-hidden row mx-0 my-3 transition-all duration-300 hover:border-blue-500 ${paymentType === payment?.name && "border-blue-500"}`}>
                                    <div className="position-relative d-flex align-items-center col-sm-4">
                                        <input 
                                            className="mx-1 my-0"
                                            style={{cursor : "pointer"}}
                                            id={index}
                                            value={payment.name}
                                            type="radio" 
                                            name="payment-gateway" 
                                            onChange={e => setPaymnetType(e.target.value)} />
                                            {t("ايصال دفع")}
                                      </div>
                                      <label htmlFor={index} className=" col-sm-8 top-0 left-0 px-3 py-4 d-flex justify-content-end" style={{cursor : "pointer"}}>
                                          <ReceiptLongIcon/>
                                      </label>
                                    </div>
                                      {
                                      payment?.name === "send_receipt" && paymentType === "send_receipt"
                                      ?
                                      <div className="col-sm-12">
                                        <input 
                                        type="file" className="form-control col-sm-12 shadow-none" id="inputPassword2"
                                        onChange={(e) => {
                                          const file = e.target.files[0];
                                          file && setReciptImage(file)
                                        }}
                                        />
                                      </div>
                                      :
                                      ""
                                      }
                                  </>
                                )
                              }
                            })
                          }
                        </div>
                        <img src={paymentsMethodsImg} alt="payments-methods" className="mt-3" />
                    </div>
                  </div>
                </div>
              </div>
              )
              :
              <h2>{t("فشل تحميل بيانات الاشتراك")}</h2>
            }
        </>
    )
}