import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import PdfViewer from "./PDFViewer";
import QuizeViewer from "./QuizeViewer";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useEffect , useRef, useState } from "react";
import timerImg from "../../images/undraw_in_no_time_-6-igu.svg";
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom';
// import 'video-react/dist/video-react.css'; 
  import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'video-react/dist/video-react.css';
import VideoJS from "./VideoJs";
import DFLessonViewrImg from "../../images/static-lesson-poster.png"
const PlyrComponent = ({ url }) => {
  const {loading:getNormalLessonLoading , data:lessonData} = useSelector(
    state => state.showSingleLessonSlicer
  )
  const playerRef = useRef(null);

  // const videoJsOptions = {
  //   controls: true,
  //   responsive: true,
  //   fluid: true,
  //   sources: [{
  //     src: 'https://ik.imagekit.io/ikmedia/example_video.mp4',
  //     type: 'video/mp4'
  //   }]
  // };
  const videoJsOptions = {
    autoplay: true, // autoplay the videos
    muted: false,  // required by newest versions of browsers for autoplay to work
    controls: true, // show controls on the player
    responsive: true, // make the player responsive
    fluid: true,
    // loop: true, // loop the video
    sources: [{
      src: url,
      // src: "https://media.w3.org/2010/05/sintel/trailer_hd.mp4",
      type: 'video/mp4'
    }],
    playbackRates: [0.5, 1, 1.5, 2],
    // adding a thumbnail poster
    // poster: 'https://ik.imagekit.io/ikmedia/example_video.mp4/ik-thumbnail.jpg?tr=w-1200,h-680'
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };
  //   const videoOptions = {
  //       type: "video",
  //       sources: [
  //         {
  //           src: url,
  //           // src: "https://www.youtube.com/embed/gmc4b433y3k?si=5eE39eKz5YPW3ERO",
  //           type: 'video/mp4',
  //           size: 720,
  //         },
  //       ],
  //       autoPlay : true
  //   };
  return (
    lessonData?.data?.lesson?.type === "youtube"
    ?
    <div className="player-wrapper relative rounded-md overflow-hidden">
      {/* <iframe width="100%" height="515" src={"https://youtu.be/ou-7AboPoXE"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
      <iframe width="100%" className="min-h-[30vh] md:h-[70vh]" src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    :
    <div className="player-wrapper rounded-md overflow-hidden">
        {/* <Plyr
          source={videoOptions}
          controls
          autoPlay
        /> */}
        {/* <ReactHlsPlayer
          // src="https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
          src={url}
          autoPlay={true}
          controls={true}
          width="100%"
          height="auto"
        /> */}
      {/* <Player autoPlay={true} >
        <source src={url} />
      </Player> */}
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      {/* <Player 
        autoPlay 
        src={url} 
      >
          <ControlBar>
          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
        </ControlBar>
      </Player> */}
          {/* <Player
            playsInline
            poster="/assets/poster.png"
            src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
          /> */}
    </div>
  );
};



export default function LessonViewer({lesson}) {
  const {t} = useTranslation();
  const {loading:getNormalLessonLoading , data:lessonData} = useSelector(
    state => state.showSingleLessonSlicer
  )
  const {loading:getSteamResponseLoading , data:streamResponse} = useSelector(
    state => state.showStreamLessonSlicer
  )
  const {loading:getExpireVideoLoading , data:expireData} = useSelector(
    state => state.getExpireVideoSlicer
  )
  const {loading:getExpirePdfLoading , data:expirePdfData} = useSelector(
    state => state.getExpirePdfSlicer
  )
  const  {loading : postAnswersLoading , data:submitQuizResponse} = useSelector(
    state => state.postStudentAnswersSlicer
  );
  const {data:targetLessonData} = useSelector(
    state => state.setLessonWhichWillCompletItSlicer
  )
  const [defaultView , setDefaultView] = useState(
    <div 
    style={{backgroundImage : `url(${DFLessonViewrImg})`}}
    className="relative col-sm-12 flex justify-center items-center min-h-[70vh] bg-no-repeat bg-cover bg-center rounded-md"
    >
    </div>
  );
  const handleSetDefView = () => {
    setDefaultView(
      // <div className="relative col-sm-12 flex justify-center items-center min-h-[70vh] bg-[url(https://img.freepik.com/premium-psd/3d-rendering-graphic-design_23-2149642724.jpg?w=900)] bg-no-repeat bg-cover bg-center rounded-md"
      // >
      // </div>
      <div className="text-center py-1 min-h-[30vh] md:min-h-[60vh] flex flex-col justify-center items-center">
          <img src={timerImg} alt="timer-img" className="w-[100px] mb-2" />
          <h2>{t("Exam has ended")}!</h2>
      </div>
    )
  }
  const [pdfURL , setPdfUrl] = useState(null)
  const [videoURL , setVideoURL] = useState(null)
  useEffect(() => {
    if(lessonData) {
      console.log(lessonData)
      switch(lessonData?.data?.lesson?.type) {
        case "video":
          setVideoURL(`${lessonData?.data?.file_url}`)
          break;
        case "youtube":
          setVideoURL(`${lessonData?.data?.lesson?.contents}`)
          break;
        case "pdf":
          setPdfUrl(`${lessonData?.data?.file_url}`)
          break;
        default:
          setDefaultView(<div 
            style={{backgroundImage : `url(${DFLessonViewrImg})`}}
            className="relative col-sm-12 flex justify-center items-center min-h-[30vh] md:min-h-[70vh] 
           bg-no-repeat bg-cover bg-center rounded-md"
          >
          </div>)
      }
    }
  },[lessonData])
  useEffect(() => {
    const handleSHowLessonContent = () => {
      switch (lesson?.type) {
        case "rich_text":
          return setDefaultView(<div dangerouslySetInnerHTML={{__html: lesson?.contents}} className="max-h-[60vh] overflow-y-auto rich-text-viewer custom-scroll"/>)
        case "pdf" :
          return setDefaultView(<PdfViewer url={pdfURL}/>)
        case "file" :
          return setDefaultView(<div className="text-center py-1 min-h-[60vh] flex justify-center items-center">
            <h2>{t("Download File")}!</h2>
          </div>)
        case "quiz" :
          return setDefaultView(<QuizeViewer lesson={lesson} handleSetDefView = {handleSetDefView}/>)
        case "video" :
          return setDefaultView(<PlyrComponent url={videoURL} />)
        case "youtube" :
          return setDefaultView(<PlyrComponent url={videoURL} />)
        default:
          return setDefaultView(<div 
            style={{backgroundImage : `url(${DFLessonViewrImg})`}}
            className="relative col-sm-12 flex justify-center items-center min-h-[30vh] md:min-h-[70vh]  bg-no-repeat bg-cover bg-center rounded-md"
          >
          </div>)
      }
    }
    handleSHowLessonContent();
  }, [lesson , pdfURL , videoURL]);
  
  useEffect(() => {
    return () => {
      setDefaultView(<div style={{backgroundImage : `url(${DFLessonViewrImg})`}} className="relative col-sm-12 flex justify-center items-center min-h-[30vh] md:min-h-[70vh] 
         bg-no-repeat bg-cover bg-center rounded-md"
      >
      </div>)
    }
  },[])

  return (
      <>
          <div className="relative col-sm-12 min-h-[30vh] md:min-h-[60vh]">
              {
              (getSteamResponseLoading || getNormalLessonLoading || getExpireVideoLoading || getExpirePdfLoading)
              ?
              <div className={`text-center py-1 min-h-[30vh]  md:min-h-[60vh] flex justify-center items-center ${(lesson?.type === "video" || lesson?.type === "file" )? "flex-col" : ""}`}>
                <Spinner animation="border" />
                {
                  lesson?.type === "file"
                  &&
                  <>{t("loading")}...</>
                }
              </div>
              :
              (
                // submitQuizResponse?.success
                // ?
                // <div className="text-center py-1 min-h-[60vh] flex flex-col justify-center items-center">
                //   <img src={timerImg} alt="timer-img" className="w-[100px] mb-2" />
                //   <h2>{t("تم انتهاء الامتحان")}!</h2>
                // </div>
                // :
                defaultView
              )
              }
          </div>
      </>
  )
}